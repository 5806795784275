<template>
  <div class='plk_import'>
    <Menu></Menu>
    <div class='import'>
      <h5>{{$t('designer.import.titlepage')}}</h5>
      <span>{{$t('designer.import.description')}}</span>
      <Uploader class="mt-3" acceptType="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed" @loadFile="loadedFile" max-size="20971520"></Uploader>
    </div>
  </div>
</template>

<script>
import { APP_FILES_TO_DISCARD_ON_LOAD } from '@/enviroment/app'
import { Store, DESIGN } from '@/store'
import i18n from './i18n'
import Uploader, { isImage, isHTML, isJavascript, isJSON, isCSS, isTextPlain, isUndefinedFile } from './components/uploaders/Uploader'
import { VIEWS } from '@/router/design'
import Menu from './components/menus/Menu'

export default {
  name: 'import',
  components: {
    Menu,
    Uploader
  },
  data () {
    return {
    }
  },
  computed: {
    views () {
      return Store.getters[DESIGN.GETTERS.GET_VIEWS]()
    },
    files () {
      return Store.getters[DESIGN.GETTERS.GET_ALL_FILES]()
    }
  },
  methods: {
    onInitComponent () {
      this.$servicesPLK.translation.init(i18n, { overwrite: false })
    },
    canAddFile (file = '') {
      return file.match(new RegExp(APP_FILES_TO_DISCARD_ON_LOAD, 'gi')) === null
    },
    loadedFile ({ content }) {
      this.$modal.show(
        {
          template: `
            <div class="dialog-create">
              <form @submit.prevent="confirm" class="m-0">
                <div class="dialog-content">
                  <div class="vue-dialog-content-title">{{title}}</div>
                  <div>{{text}}</div>
                </div>
                <div class="vue-dialog-buttons">
                  <button type="button" @click="cancel" class="vue-dialog-button" style="flex: 1 1 50%;">
                    {{$t('designer.import.acceptzip.cancel')}}
                  </button>
                  <button type="submit" class="vue-dialog-button" style="flex: 1 1 50%;">
                    {{$t('designer.import.acceptzip.confirm')}}
                  </button>
                </div>
              </form>
            </div>
          `,
          props: ['name', 'title', 'text', 'onConfirm'],
          methods: {
            confirm () {
              if (this.onConfirm) this.onConfirm()
            },
            cancel () {
              this.$emit('close')
            }
          }
        },
        {
          text: this.$t('designer.import.acceptzip.text'),
          title: this.$t('designer.import.acceptzip.title'),
          name: 'createFile',
          onConfirm: () => {
            Store.commit({
              type: DESIGN.MUTATIONS.RESTORE_BASE
            })
            this.$modal.hideAll()
            this.$nextTick(() => {
              this.importFiles({ content })
              this.importViews({ content })
              this.$router.push({
                name: VIEWS.FILES.name
              })
            })
          }
        },
        { height: 'auto', draggable: true, adaptive: true, class: 'vue-dialog' }
      )
    },
    importViews ({ content }) {
      content.forEach(async (relativePath, zipEntry) => {
        if (!zipEntry.dir && relativePath.match(new RegExp('^views/.*\\.json$', 'gi'))) {
          const fileContent = await content.file(relativePath).async('string')
          if (fileContent) {
            try {
              const viewName = relativePath.replace(new RegExp('views/(.*)\\.json', 'gi'), '$1')
              const view = JSON.parse(fileContent)
              Store.commit({
                type: this.views[viewName] ? DESIGN.MUTATIONS.UPDATE_VIEW : DESIGN.MUTATIONS.CREATE_VIEW,
                view: viewName,
                actions: view.actions,
                metaInfo: view.metaInfo,
                viewClass: view.viewClass,
                components: view.components
              })
            } catch (e) {
              const msg = this.$t('designer.import.error2import', { filename: zipEntry.name })
              this.$servicesPLK.event.emitEvent(this.$servicesPLK.event.BASIC_ACTIONS.ERROR, { type: 'error', msg })
            }
          }
        }
      })
    },
    importFiles ({ content }) {
      content.forEach(async (relativePath, zipEntry) => {
        try {
          if (!zipEntry.dir && this.canAddFile(relativePath)) {
            const fileContent = await content.file(relativePath).async('base64')
            if (fileContent) {
              let content2save = this.evaluate2save(zipEntry, fileContent)
              Store.commit({
                type: this.files.indexOf(relativePath) === -1 ? DESIGN.MUTATIONS.CREATE_FILE : DESIGN.MUTATIONS.UPDATE_FILE_CONTENT,
                filename: relativePath,
                content: content2save
              })
            }
          }
        } catch (e) {
          console.error(`Imposible load file ${zipEntry.name}`)
          const msg = this.$t('designer.import.error2import', { filename: zipEntry.name })
          this.$servicesPLK.event.emitEvent(this.$servicesPLK.event.BASIC_ACTIONS.ERROR, { type: 'error', msg })
        }
      })
    },
    evaluate2save (zipEntry, contentStr) {
      let content2save = {
        isImage: isImage({ filename: zipEntry.name }),
        isHtml: isHTML({ filename: zipEntry.name }),
        isJavascript: isJavascript({ filename: zipEntry.name }),
        isJSON: isJSON({ filename: zipEntry.name }),
        isCSS: isCSS({ filename: zipEntry.name }),
        isTextPlain: isTextPlain({ filename: zipEntry.name }),
        isUndefinedFile: isUndefinedFile({ filename: zipEntry.name })
      }
      const nameExtension = (zipEntry.name || '').split('.').slice(-1)[0].toLowerCase()
      const base64content = `data:${content2save.isImage ? `image/${nameExtension}` : 'application/octet-stream'};base64,${contentStr}`
      content2save.data = !content2save.isImage && !content2save.isUndefinedFile ? atob(contentStr) : base64content
      content2save.base64Data = base64content
      try {
        if (content2save.isJSON) {
          let validateCode = JSON.parse(content2save.data)
          content2save.data = JSON.stringify(validateCode, null, 2)
          content2save.base64Data = btoa(content2save.data)
        }
      } catch (ex) {}
      return content2save
    }
  }
}
</script>
<style lang="scss">
  .dialog-create {
    .dialog-content {
      padding: 2rem;
    }
  }
</style>
<style scoped lang='scss'>
  .plk_import {
    .import {
      padding: 3vh;
      padding-left: calc(3vh + 50px);
    }
  }
</style>
